
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import Widget from '@/components/Widget/Widget.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import BlockRule from '@/models/BlockRule'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import SelectOption from '@/models/interface/SelectOption'
import Inventory from '@/models/Inventory'
import { CompanyTypes, IndustryOptions, SubIndustryOptions } from '@/models/interface/Common'
import InspectTargetAll from '@/components/InspectTarget/all.vue'
import PublisherPicker from '@/components/PublisherPicker/PublisherPicker.vue'
import IndustryTargeting from '@/components/IndustryTargeting/IndustryTargeting.vue'
import InventoryTargetting from '@/pages/LineItem/components/Targetting/InventoryTargetting.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import router from '@/Routes'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    Widget,
    FormInput,
    SelectPicker,
    IconAction,
    CompanyPicker,
    IndustryTargeting,
    PublisherPicker,
    InspectTargetAll,
    InventoryTargetting,
    FooterNav,
  },
})
export default class BlockRuleEdit extends ViewModel {
  @Ref() validator!: any

  @Ref() readonly inventoryTargetAllRefInfo: any

  @Ref() readonly industryTargetAllRefInfo: any

  public report_name_options: SelectOption[] = []

  public busy: boolean = true

  public loading: boolean = true

  public block_rule: BlockRule = new BlockRule()

  // watch block_rule.rule_type

  @Watch('block_rule.rule_type')
  public onRuleTypeChange() {
    if (!this.busy && !this.loading) {
      this.block_rule.rule_ids.include = []
      this.block_rule.rule_ids.exclude = []
    }
  }

  @Watch('block_rule.target_type')
  public onTargetTypeChange() {
    if (!this.busy && !this.loading) {
      this.block_rule.target_ids.include = []
      this.block_rule.target_ids.exclude = []
    }
  }

  public get id(): any {
    if (this.$route.params.hasOwnProperty('id')) {
      return this.$route.params.id
    }
    return false
  }

  public get title(): string {
    return this.id ? 'Edit Rule Block' : 'Create Rule Block'
  }

  public get options() {
    let { rule_types, target_types } = BlockRule
    return { rule_types, target_types }
  }

  public get company_industry_options() {
    return IndustryOptions
  }

  public targettingCollapseStates: any = {
    inventory_targetting: {
      loaded: false,
      toggle: false,
    },
    industry: {
      loaded: false,
      toggle: false,
    },
  }

  public get targets() {
    return {
      include_adunits: this.block_rule.target_ids.include,
      exclude_adunits: this.block_rule.target_ids.exclude,
    }
  }

  public set targets(value: any) {
    this.block_rule.target_ids.include = value.include_adunits
    this.block_rule.target_ids.exclude = value.exclude_adunits
  }

  public toggleCollapse(key: any, timeout: number = 100) {
    let { loaded, toggle } = this.targettingCollapseStates[key]

    if (!loaded) {
      setTimeout(() => {
        this.targettingCollapseStates[key].loaded = true
      }, timeout)
    }
  }

  public mounted() {
    Inventory.loadReportNameOptions().then((o: any) => {
      this.report_name_options = o
    })
    this.load()
  }

  public load() {
    if (this.id) {
      BlockRule.find(this.id)
        .then(res => {
          this.block_rule = res

          this.loading = false
          setTimeout(() => {
            this.busy = false
          }, 500)
        })
        .catch(() => {
          this.loading = false
          this.busy = false
        })
    } else {
      this.busy = false
      this.loading = false
    }
  }

  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate', {
      to,
      from,
    })
    if (to.params.id !== from.params.id) {
      // React to param changes here
      // E.g., fetch new data for the new id
      next()
    } else {
      next(false) // Abort the navigation
    }
  }

  public save() {
    this.loading = true
    this.block_rule
      .save()
      .then(res => {
        console.log({ res })
        this.loading = false
        const data = res.data.result.block_rule
        console.log('data', data)

        if (res.status === 200) {
          if (!this.id || this.$route.params.id !== data.id) {
            this.$router.replace({
              name: 'block-rule',
              params: { id: data.id },
            })
          }
          this.block_rule = BlockRule.toObject(data)
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    this.$router.push({ name: 'block-rules' })
  }

  public get industryList() {
    return [...IndustryOptions, ...Object.values(SubIndustryOptions).flat()]
  }

  public get selectedIndustries() {
    let include = this.industryList.filter((ind: any) =>
      this.block_rule.rule_ids.include.includes(ind.value))

    let exclude = this.industryList.filter((ind: any) =>
      this.block_rule.rule_ids.exclude.includes(ind.value))

    return {
      include,
      exclude,
    }
  }
}
