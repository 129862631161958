
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Inventory from '@/models/Inventory'
import GeoTargettingModule from '@/models/GeoTargettingModule'
import { groupBy } from 'lodash'
import { GeoTargetingNode, InventoryTargetingNode } from '@/models/interface/TargetingNode'
import IndustryTargeting from '../IndustryTargeting/IndustryTargeting.vue'

@Component
export default class InspectTargetAll extends ViewModel {
  @Prop({ default: false })
  public componentMode!: boolean | string

  @Prop({ default: false })
  public listedCol!: boolean

  @Prop()
  public target!: string

  @Prop()
  public included!: any

  @Prop()
  public excluded!: any

  @Prop()
  public ignoreWatch!: boolean

  @Prop({ default: 3 })
  public displayQuantity!: number

  public loading: boolean = false

  public included_list_options: any = []

  public excluded_list_options: any = []

  public search_query: string = ''

  public show_search_input: boolean = true

  public debounc: any = 3000

  public ready: boolean = false

  @Prop({ default: false })
  public allListed!: boolean

  @Watch('included')
  public onIncludedChange(val: any) {
    if (!this.ignoreWatch) {
      if (!this.ready) return

      if (!val || !val.length) {
        Vue.set(this, 'included_list_options', [])
      } else {
        this.loadTargetData(this.target)
      }
    }
  }

  @Watch('excluded')
  public onExcludedChange(val: any) {
    if (!this.ignoreWatch) {
      if (!this.ready) return

      if (!val || !val.length) {
        Vue.set(this, 'excluded_list_options', [])
      } else {
        this.loadTargetData(this.target)
      }
    }
  }

  public get hasTypes() {
    return (
      this.included_list_options.some((v: any) => v.type ?? false)
      || this.excluded_list_options.some((v: any) => v.type ?? false)
    )
  }

  // get remaining options to display on tooltip filter out filterOut(this.included_list_options)
  public get remaining_options() {
    let obj = {
      included: [],
      excluded: [],
    }

    if (this.included) {
      obj.included = this.included_list_options.filter((_, index) => index >= this.displayQuantity)
    }
    if (this.excluded) {
      obj.excluded = this.excluded_list_options.filter((_, index) => index >= this.displayQuantity)
    }

    return obj
  }

  public get selected() {
    return {
      included: this.included_list_options?.length ?? 0,
      excluded: this.excluded_list_options?.length ?? 0,
    }
  }

  public filterByQuery(arr: any[]) {
    if (!this.search_query || !this.search_query.length || !this.search_query.trim().length) {
      return arr
    }
    return arr.filter(data => data.name.toLowerCase().includes(this.search_query.toLowerCase()))
  }

  public filterOut(arr: any[], bigger = false) {
    let cutted = arr.filter((_, index) => index <= this.displayQuantity - 1)
    if (this.allListed) cutted = arr
    let result = cutted.filter(data => {
      if (this.search_query && this.search_query.length) {
        data.name.toLowerCase().includes(this.search_query.toLowerCase())
      }
      return data
    })

    return result
  }

  public mounted() {
    this.loadTargetData(this.target)
  }

  public loadTargetData(target: string) {
    if (this.loading) return
    this.loading = true

    const targets: any = {
      inventory: () => this.loadInventory(),
      'geo-targeting': () => this.loadGeoTargeting(),
      'ip-targeting': () => this.loadIpTargettings(),
      'custom-targeting': () => this.loadCustomTargeting(),
      'device-targeting': () => this.loadCustomDeviceTargeting(),
      'value-targeting': () => this.loadValuesTargeting(),
    }

    targets[target]()
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
      .finally(() => {
        if (!this.included || !this.included.length) {
          Vue.set(this, 'included_list_options', [])
        }
        if (!this.excluded || !this.excluded.length) {
          Vue.set(this, 'excluded_list_options', [])
        }
        setTimeout(() => {
          this.ready = true
        }, 1500)
      })
  }

  public async loadInventory() {
    let response = await Inventory.loadInventories({
      selected_inventory_ids: [this.included, this.excluded].flat(),
    })

    this.included_list_options = response.selected_inventories
      .filter((v: any) => this.included.some((i: any) => i === v.id))
      .map((v: any) => {
        v.value = v.id
        return v
      })

    this.excluded_list_options = response.selected_inventories
      .filter((v: any) => this.excluded.some((i: any) => i === v.id))
      .map((v: any) => {
        v.value = v.id
        return v
      })

    return response
  }

  public async refOnlyDONTUSE_loadInventory(
    { include, all }: { include?: boolean; all?: boolean } = {
      include: true,
      all: true,
    },
  ) {
    if (all) {
      if (this.included && this.included.length) {
        await Inventory.loadInventories({
          selected_inventory_ids: this.included,
        }).then((response: any) => {
          this.included_list_options = response.selected_inventories
        })
      } else {
        this.included_list_options = []
      }
      if (this.excluded && this.excluded.length) {
        await Inventory.loadInventories({
          selected_inventory_ids: this.excluded,
        }).then((response: any) => {
          this.excluded_list_options = response.selected_inventories
        })
      }
    } else {
      let values = include ? this.included : this.excluded

      await Inventory.loadInventories({
        selected_inventory_ids: values,
      }).then((response: any) => {
        if (include) this.included_list_options = response.selected_inventories
        else {
          this.excluded_list_options = response.selected_inventories
        }

        return response
      })
    }

    Promise.resolve()
  }

  /**
   * Refactored loadGeoTargeting
   */
  public async loadGeoTargeting() {
    let search_for = [this.included, this.excluded].flat()
    let geo: GeoTargettingModule = new GeoTargettingModule()
    let grouped = groupBy(search_for, 'type') // type only exists in Geo Targetting

    let promises = []

    for (let key in grouped) {
      if (key !== 'zip') {
        promises.push(
          geo.geoSearchAll({
            value: grouped[key].map((v: any) => v.value),
            type: key,
          }),
        )
      }
    }

    let results = await Promise.all(promises)
    results = results.flat()

    if (results && results.length) {
      let mapped = results
        .map((v: any) => v.data.result)
        .map((options: any) =>
          options.options.map((v: any) => ({
            name: v.name,
            type: v.type,
            value: v.value,
          })))

      mapped = mapped.flat()

      this.included_list_options = mapped.filter((v: any) =>
        this.included.some((i: any) => i.value == v.value && i.type === v.type))

      this.excluded_list_options = mapped.filter((v: any) =>
        this.excluded.some((i: any) => i.value == v.value && i.type === v.type))
    }

    await this.loadZipcodes()
  }

  public async loadZipcodes() {
    return new Promise((resolve, reject) => {
      const mapZip = (zip: string) => ({
        name: zip,
        value: zip,
        type: 'zip',
      })

      // add all_zips they need to splited included_list_options and excluded_list_options
      let mapped_includes = this.included
        .filter((inc: any) => inc.type === 'zip')
        .map((v: any) => mapZip(v.value))

      if (mapped_includes && mapped_includes.length) {
        this.included_list_options = this.included_list_options.concat(mapped_includes)
      }

      let mapped_excludes = this.excluded
        .filter((exc: any) => exc.type === 'zip')
        .map((v: any) => mapZip(v.value))

      if (mapped_excludes && mapped_excludes.length) {
        this.excluded_list_options = this.excluded_list_options.concat(mapped_excludes)
      }

      setTimeout(() => {
        resolve(true)
      }, 1000)
    })
  }

  public async loadIpTargettings() {
    if (this.included && this.included.length) {
      this.included_list_options = this.included.map((v: any) => ({
        name: v,
        value: v,
      }))
    }

    if (this.excluded && this.excluded.length) {
      this.excluded_list_options = this.excluded.map((v: any) => ({
        name: v,
        value: v,
      }))
    }
    return Promise.resolve()
  }

  public async loadCustomTargeting() {
    this.included_list_options = this.included.map((v: any) => ({
      name: v,
      value: v,
    }))
    this.excluded_list_options = this.excluded.map((v: any) => ({
      name: v,
      value: v,
    }))
    return Promise.resolve()
  }

  public loadValuesTargeting() {
    this.included_list_options = this.included.map((v: any) => ({
      name: v.name,
      value: v.value,
    }))
    this.excluded_list_options = this.excluded.map((v: any) => ({
      name: v.name,
      value: v.value,
    }))

    return Promise.resolve()
  }

  public async loadCustomDeviceTargeting() {
    let device_categories: any = [
      {
        name: 'Connected TV',
        value: 'ctv',
      },
      {
        name: 'Desktop',
        value: 'desktop',
      },
      {
        name: 'Mobile',
        value: 'mobile',
      },
    ]
    this.included_list_options = this.included.map((v: any) => ({
      name: device_categories.find((dc: any) => dc.value === v).name,
      value: v,
    }))
    this.excluded_list_options = this.excluded.map((v: any) => ({
      name: device_categories.find((dc: any) => dc.value === v).name,
      value: v,
    }))
  }

  // used externally
  public addExcluded(node: any) {
    let exists = this.excluded_list_options.some((v: any) => v.value === node.value)

    if (!exists) {
      this.excluded_list_options.push(node)
      let included = this.included_list_options.findIndex((v: any) => v.value === node.value)
      if (included > -1) {
        this.included_list_options.splice(included, 1)
      }
    }

    // check if it exists in included if it does remove it
  }

  public addIncluded(node: any) {
    let exists = this.included_list_options.some((v: any) => v.value === node.value)

    if (!exists) {
      this.included_list_options.push(node)
      let excluded = this.excluded_list_options.findIndex((v: any) => v.value === node.value)
      if (excluded > -1) {
        this.excluded_list_options.splice(excluded, 1)
      }
    }
  }

  public removeExcluded(node: any) {
    let copy = [...this.excluded_list_options]

    let index = copy.findIndex((v: any) => v.value === node.value)

    copy.splice(index, 1)

    Vue.set(this, 'excluded_list_options', copy)
  }

  public removeIncluded(node: any) {
    const copy = [...this.included_list_options]

    let index = copy.findIndex((v: any) => v.value === node.value)

    copy.splice(index, 1)

    Vue.set(this, 'included_list_options', copy)
  }

  public nodeLocation(node: any) {
    let included = this.included_list_options.some(
      (v: any) => v.value === node.value || v.id === node.id,
    )
    if (included) return 'included'
    let excluded = this.excluded_list_options.some(
      (v: any) => v.value === node.value || v.id === node.id,
    )

    return excluded ? 'excluded' : false
  }

  /**
   * Update the list of options by demand
   * Each time you include or exclude anything outside this component to targeting
   * call this function
   * @param args
   */
  public syncChanges(args: InventoryTargetingNode | GeoTargetingNode | IndustryTargeting | any) {
    if (args.action === 'include') {
      this.addIncluded(args)
    } else if (args.action === 'exclude') {
      this.addExcluded(args)
    } else if (args.action === 'remove') {
      let place = this.nodeLocation(args)
      if (place && place === 'included') {
        this.removeIncluded(args)
      }
      if (place && place === 'excluded') {
        this.removeExcluded(args)
      }
    }

    if (args.sync) {
      setTimeout(() => {
        this.loadTargetData(this.target)
      }, 200)
    }

    setTimeout(() => {
      if (!this.included || !this.included.length) {
        Vue.set(this, 'included_list_options', [])
      }

      if (!this.excluded || !this.excluded.length) {
        Vue.set(this, 'excluded_list_options', [])
      }
    }, 500)
  }
}
