import { render, staticRenderFns } from "./CloseTag.vue?vue&type=template&id=5d81d3ee&scoped=true&"
import script from "./CloseTag.vue?vue&type=script&lang=ts&"
export * from "./CloseTag.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d81d3ee",
  null
  
)

export default component.exports