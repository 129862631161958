
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CloseTag extends ViewModel {
  @Prop({ required: true })
  public label!: string

  @Prop({ required: true })
  public title!: string

  @Prop({ required: true })
  public variant!: string
}
