import BlockRule from '@/models/BlockRule'
import router from '@/Routes'

export default [
  {
    key: 'id',
    label: 'ID',
    thClass: 'align-middle text-center',
    sortable: true,
    show: false,
  },
  {
    key: 'name',
    label: 'Name',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: 'text-capitalize text-center align-middle',
  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: 'text-capitalize text-center align-middle',
  },
  {
    key: 'rule_type',
    label: 'Rule Type',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: 'text-capitalize text-center align-middle',
  },
  {
    key: 'rule_ids',
    label: 'Rules',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: 'text-capitalize text-center align-middle',
  },
  {
    key: 'target_type',
    label: 'Target Type',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: 'text-capitalize text-center align-middle',
  },
  {
    key: 'target_ids',
    label: 'Targets',
    thClass: 'align-middle text-center',
    sortable: true,
    show: true,
    class: 'text-capitalize text-center align-middle',
  },
  {
    key: 'action', // TODO continue here, its not rendering the actions
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    tdClass: 'actions',
    thStyle: { width: '160px' },
    class: 'text-center align-middle text-capitalize',
    thClass: 'align-middle text-center',
    actions: [
      // view
      {
        icon: 'pencil',
        title: 'Edit',
        top_level: true,
        event: 'edit',
        action: (block_ruke: BlockRule) => {
          router.push({
            name: 'block-rule',
            params: { id: block_ruke.id },
          })
        },
      },

      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        action: (block_ruke: BlockRule, table: any) => {
          block_ruke.delete().then(() => {
            table.refresh()
          })
        },
      },
      // switch status
      {
        icon: 'exchange-alt',
        title: 'Toggle Status',
        event: 'toggle_status',
        action: (block_ruke: BlockRule) => {
          block_ruke.status = !block_ruke.status
          block_ruke.save()
        },
      },
    ],
  },
]
