
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { IndustryOptions, SubIndustryOptions } from '@/models/interface/Common'
import CloseTag from '@/components/CloseTag/CloseTag.vue'

@Component({
  components: {
    FormInput,
    IconAction,
    CloseTag,
  },
})
export default class IndustryTargeting extends ViewModel {
  @Prop()
  value!: any

  public filter: any = ''

  public matching_case: number = 0

  public industryList: any = []

  public subIndustryOptions: any = []

  public loading: boolean = false

  public ready: boolean = false

  public collapseState: any = {}

  public selected_nodes: any = []

  @Watch('filter')
  public onFilterChange(val: any) {
    if (!val || !val.length) {
      Object.keys(this.collapseState).forEach((key: any) => {
        this.collapseState[key] = false
      })

      this.removHighlight()
    }
  }

  public get filteredList() {
    if (!this.filter.length) return this.industryList

    const filteredIndustries = this.industryList.filter((industry: any) => {
      const industryMatches = industry.name.toLowerCase().includes(this.filter.toLowerCase())
        || industry.value.toLowerCase().includes(this.filter.toLowerCase())

      // Check if any of the sub-industries match the filter
      const subIndustries = SubIndustryOptions[industry.value] || []
      const subIndustryMatches = subIndustries.some(
        (subIndustry: any) =>
          subIndustry.name.toLowerCase().includes(this.filter.toLowerCase())
          || subIndustry.value.toLowerCase().includes(this.filter.toLowerCase()),
      )

      if (subIndustryMatches) {
        // open parent
        this.collapseState[industry.value] = true
      }

      return industryMatches || subIndustryMatches
    })

    this.highLightText()

    return filteredIndustries
  }

  public mounted() {
    this.ready = true

    let temp = SubIndustryOptions

    this.industryList = IndustryOptions.map((industry: any) => {
      industry.id = industry.value
      temp[industry.value] = temp[industry.value]
        .map((subIndustry: any) => {
          subIndustry.id = `${industry.value}--${subIndustry.value}`
          subIndustry.value = `${industry.value}--${subIndustry.value}`
          subIndustry.parent = industry.value
          return subIndustry
        })
        .filter(
          // filter unique sub industries for the parent
          (option: any, index: any, self: any) =>
            index === self.findIndex((o: any) => o.value === option.value),
        )

      return industry
    }).filter(
      (option: any, index: any, self: any) =>
        index === self.findIndex((o: any) => o.value === option.value),
    )

    this.subIndustryOptions = temp

    this.industryList.forEach((industry: any) => {
      Vue.set(this.collapseState, industry.value, false)
    })

    this.loadSelected()
  }

  public loadSelected() {
    let subInd = Object.values(SubIndustryOptions).flat()

    if (this.value && this.value.include) {
      this.value.include.forEach((id: string) => {
        let node = this.industryList.find((n: any) => n.value === id)
        const sub: any = subInd.find((n: any) => n.value === id)

        if (node) {
          this.select({ ...node, action: 'include' })
        }

        if (sub) {
          this.select({ ...sub, action: 'include' })
        }
      })
    }

    if (this.value && this.value.exclude) {
      this.value.exclude.forEach((id: string) => {
        let node = this.industryList.find((n: any) => n.value === id)
        let sub: any = subInd.find((n: any) => n.value === id)

        if (node) {
          this.select({ ...node, action: 'exclude' })
        }

        if (sub) {
          this.select({ ...sub, action: 'exclude' })
        }
      })
    }
  }

  public scrollToFirstChildEl(ind_value: string) {
    this.$nextTick(() => {
      const mainList = document.getElementById('main-list')
      const firstChildEl = document.getElementById(`sub-industry-${ind_value}-0`)

      if (mainList && firstChildEl) {
        setTimeout(() => {
          const childPosition = firstChildEl.getBoundingClientRect().top - mainList.getBoundingClientRect().top
          const removeHeightFromTop = 80 // Remove height of the header
          mainList.scrollTo({
            top: mainList.scrollTop + childPosition - removeHeightFromTop, // Use scrollTop to maintain current scroll position
            behavior: 'smooth',
          })
        }, 100)
      }
    })
  }

  public removHighlight() {
    const highlight = document.querySelectorAll('.highlight')
    highlight.forEach((h: any) => {
      h.classList.remove('highlight')
    })
  }

  public highLightText() {
    this.$nextTick(() => {
      this.removHighlight()
      this.matching_case = 0
      const filter = this.filter.trim()
      if (!filter || !filter.length) return

      const mainList = document.getElementById('main-list')
      if (!mainList) return

      // Create a case-insensitive regular expression
      const regex = new RegExp(`(${filter})`, 'gi')

      // Query all list items
      const listItems = mainList.querySelectorAll('li')

      listItems.forEach(item => {
        // Check if item text contains the filter text
        if (regex.test(item.textContent || '')) {
          // Highlight the matching text
          const nameSlot = item.querySelector('.name-slot')
          if (nameSlot) {
            nameSlot.innerHTML = nameSlot.textContent?.replace(regex, '<b class="highlight">$1</b>') || ''
          }
        }
      })

      const highlight = mainList.querySelectorAll('.highlight')
      this.matching_case = highlight.length
    })
  }

  public isSelected(node: any) {
    return this.selected_nodes.some((sn: any) => sn.name === node.name)
  }

  public isIncluded(node: any) {
    return this.selected_nodes.some((sn: any) => sn.id === node.id && sn.action === 'include')
  }

  public isExcluded(node: any) {
    return this.selected_nodes.some((sn: any) => sn.id === node.id && sn.action === 'exclude')
  }

  public select(node: any, include: boolean = true) {
    let index = this.selected_nodes.findIndex((s: any) => s.id === node.id)
    let _action = include ? 'include' : 'exclude'
    let obj: any = {
      ...node,
      action: _action,
      id: node.value,
    }

    if (index > -1) {
      Vue.set(this.selected_nodes[index], 'action', _action)
      this.updatedModel(this.selected_nodes[index])
    } else {
      this.selected_nodes.push(obj)
      this.updatedModel(obj)
    }

    this.emit()
  }

  public remove(index: number) {
    this.updatedModel({ ...this.selected_nodes[index], action: 'remove' })

    setTimeout(() => {
      this.selected_nodes.splice(index, 1)
      this.emit('remove func')
    }, 200)
  }

  public removeSelectionById(id: string) {
    const index = this.selected_nodes.findIndex((s: any) => s.id === id)
    if (index > -1) {
      this.remove(index)
      return true
    }
    return false
  }

  public removeAll() {
    this.selected_nodes = []
    this.emit()
    this.updatedModel(null, true)
  }

  public emit(origin: any = null) {
    setTimeout(() => {
      let include = this.selected_nodes.filter((sn: any) => sn.action === 'include')
      let exclude = this.selected_nodes.filter((sn: any) => sn.action === 'exclude')

      const map_values = (arr: any) => arr.map((n: any) => n.id)

      this.$emit('input', {
        ...this.value,
        include: map_values(include),
        exclude: map_values(exclude),
      })
    }, 400)
  }

  public updatedModel(node: any, sync = false) {
    if (!node) {
      this.$emit('updated-model', { sync })
      return
    }
    node.origin = 'industries'
    if (!this.ready) return
    setTimeout(() => {
      this.$emit('updated-model', node)
    }, 100)
  }

  public get selectedNodesInOrder() {
    // Group nodes by parent
    let groupedNodes: any = {}

    this.selected_nodes.forEach((node: any) => {
      if (!node.parent) {
        // It's a parent node
        if (!groupedNodes[node.value]) {
          groupedNodes[node.value] = { parent: node, children: [] }
        } else {
          groupedNodes[node.value].parent = node
        }
      } else {
        // It's a child node
        if (!groupedNodes[node.parent]) {
          groupedNodes[node.parent] = { parent: null, children: [] }
        }
        groupedNodes[node.parent].children.push(node)
      }
    })

    let orderedArray: any[] = []

    Object.keys(groupedNodes).forEach(key => {
      if (groupedNodes[key].parent) {
        orderedArray.push(groupedNodes[key].parent)
      }
      orderedArray.push(...groupedNodes[key].children)
    })

    return orderedArray
  }

  public getParentName(parent_id: any) {
    return this.industryList.find((n: any) => n.value === parent_id)?.name || ''
  }

  // limit string len
  public limitString(str: string, limit: number) {
    return str.length > limit ? `${str.substring(0, limit)}...` : str
  }
}
