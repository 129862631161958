
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ViewModel from '@/models/ViewModel'

import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

import BlockRule from '@/models/BlockRule'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { currencyMask } from '@/models/interface/Masks'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { startCase } from 'lodash'
import { IndustryOptions, SubIndustryOptions } from '@/models/interface/Common'
import block_home_table_fields from './block-rules-fields'

@Component({
  components: {
    Widget,
    DataTable,
    SearchInput,
    IconAction,
    FormInput,
    DatePicker,
  },
})
export default class BlockRulesHome extends ViewModel {
  @Ref() readonly dataTable!: any

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public query: string[] = []

  public page_size: number = 50

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public fields: any = []

  public show_filter_helper: boolean = false

  public fieldFilters: any = {
    start_at: '',
    end_at: '',
    created_at: '',
    updated_at: '',
    status: '',
    is_new_business: '',
    quarters: '',
    closed_at: '',
    pitched_at: '',
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'publisher',
        key: 'publisher_id',
        type: 'publisher',
      },
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'status:active',
        value: 'status:active',
        description: 'Filter active rules',
      },
      {
        name: 'status:inactive',
        value: 'status:inactive',
        description: 'Filter inactive rules',
      },
      {
        name: 'id',
        value: 'id',
      },
      // industry, sub_industry
      // {
      //   name: 'industry',
      //   key: 'industry',
      // },
      // {
      //   name: 'sub_industry',
      //   key: 'sub_industry',
      // },
    ],
    adunit_fields: [
      {
        name: 'adunit',
        value: 'adunit',
        key: 'adunit',
      },
      {
        name: 'adunit_id',
        key: 'adunit_id',
      },
    ],

    report_name: [
      {
        name: 'report_name',
        key: 'report_name',
      },
    ],
  }

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  @Watch('fields', { deep: true })
  public onFieldsChange() {
    if (this.ready) {
      this.syncFilters()
    }
  }

  public get masks() {
    return { currency: currencyMask }
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get active_field_keys() {
    return this.show_fields.map((f: any) => f.key)
  }

  public rules(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return BlockRule.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  mounted() {
    this.loadFilters()

    this.fields = block_home_table_fields

    IndustryOptions.forEach(industry => {
      this.query_settings.custom_fields.push({
        name: `industry:${industry.name}`,
        value: `industry:${industry.value}`,
      })
    })

    Object.values(SubIndustryOptions)
      .flat()
      .forEach((sub_industry: any) => {
        this.query_settings.custom_fields.push({
          name: `sub_industry:${sub_industry.name}`,
          value: `sub_industry:${sub_industry.value}`,
        })
      })
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'block-rules',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })

    system.updateState({
      name: 'fields',
      type: 'block-rules',
      data: this.active_field_keys,
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)

    system.getFilter('block-rules').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'block-rules',
      data: null,
    })
  }

  public refresh() {
    this.dataTable.refresh()
    this.$root.$emit('bv::hide::popover')
  }

  public resetFilters() {
    this.fieldFilters = {
      start_at: '',
      end_at: '',
      created_at: '',
      updated_at: '',
      status: '',
      is_new_business: '',
      quarters: '',
      closed_at: '',
      pitched_at: '',
    }
    this.query = ['is:mine']
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }

  public format(value: string) {
    return startCase(value)
  }
}
