/* eslint max-classes-per-file: ["error", 2] */
import Model from './Model'

class InventoryTargetingNode extends Model {
  public action: any = null

  public adserver: any = null

  public fallback_inventory_id: any = null

  public hierarchy_ids: any = null

  public id: any = null

  public is_fallback: any = null

  public name: any = null

  public original: any = null

  public parent_id: any = null

  public origin: any = 'inventory-targeting'

  public get value() {
    return this.id
  }
}

class GeoTargetingNode extends Model {
  public action: any = null

  public id: any = null

  public name: any = null

  public value: any = null

  public country_code: any = null

  public state_code: any = null

  public type: any = null

  public origin: any = 'geo-targeting'
}

// export classes
export { InventoryTargetingNode, GeoTargetingNode }
