import { capitalize } from './interface/Common'
import Model from './interface/Model'
import SelectOption from './interface/SelectOption'
import WebMessage from './WebMessage'

type Separator = { include: any[]; exclude: any[] }

export default class BlockRule extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'block_rule' as string | null,
      plural: 'block_rules' as string | null,
    },
  }

  public id: any = null

  public name: string = ''

  public status: boolean = true

  public rule_type: string = 'advertiser'

  public rule_ids: Separator = { include: [], exclude: [] }

  public target_type: string = 'adunits'

  public target_ids: Separator = { include: [], exclude: [] }

  // rule_type: advertiser, industry
  public static rule_types = [
    new SelectOption('Advertiser', 'advertiser'),
    new SelectOption('Industry', 'industry'),
  ]

  public static target_types = [
    new SelectOption('Adunits', 'adunits'),
    new SelectOption('Reporting Name', 'reporting_name'),
    new SelectOption('Publisher', 'publisher'),
  ]

  public toObject(source: any): any {
    let instance = this.clone()

    Object.assign(instance, source)

    return instance
  }

  public onDelete(response: any) {
    const data = response.data.result
    WebMessage.success(`Block Rule "${data.name}" deleted!`)

    return response
  }
}
